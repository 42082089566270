.footer {
  background-color: black;
  padding: 40px;
  .title {
    display: flex;
    gap: 15px;
    align-items: center;
    img {
      width: 35px;
    }
    span {
      color: #fff;
      font-family: Lato;
      font-size: 18px;
      font-weight: 700;
    }
  }

  .inner {
    display: flex;
    justify-content: space-between;
    padding: 20px 0px;
    .socialDiv {
      // width: 500px;
      width: 50%;
      height: 300px;
      //   background-color: cyan;
      display: grid;
      grid-template-columns: 50% 50%;
      align-items: center;
      gap: 10px 60px;

      .innerBlock {
        display: flex;
        flex-direction: column;
        gap: 10px;
        color: #fff;
        .addresstitle {
          display: flex;
          gap: 15px;
          align-items: center;
          img {
            width: 35px;
          }
          span {
            font-family: Lato;
            font-size: 18px;
            font-weight: 600;
          }
        }
        p {
          font-family: Lato;
          font-size: 15px;
        }
        .iconsDiv {
          display: flex;
          gap: 10px;
          img {
            width: 32px;
            cursor: pointer;
          }
        }
      }
    }

    .linksDiv {
      width: 50%;
      height: 300px;
      display: flex;
      justify-content: space-around;
      color: #fff;

      .linkTitle {
        font-family: Lato;
        font-size: 24px;
        font-weight: 700;
        margin-bottom: 10px;
      }
      .linkList {
        list-style: none;
        p {
          padding: 10px 0px;
        }
      }
    }
  }
}
.mobileFooter{
  display: none;
}

@media only screen and (max-width: 768px) {
  .footer {
    display: none;
  }
  .mobileFooter {
    background-color: black;
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    padding: 60px 30px;
    .singleDetail {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .left {
        display: flex;
        gap: 15px;
        align-items: center;
        img {
          width: 45px;
        }
        p {
          color: white;
          font-size: 20px;
          font-family: Lato;
          font-weight: 600;
        }
      }
      .right {
        width: 25px;
      }
      .rotate {
        transform: rotate(180deg);
        width: 25px;
      }
    }
    .detail {
      font-size: 18px;
      font-weight: 400;
      color: #fff;
      font-family: Lato;
      border-bottom: 1px solid #272727;
      padding: 10px 0px;

      p {
        padding: 4px 0px;
      }
    }
    .followUs {
      color: #fff;
      font-family: Lato;
      font-size: 18px;
      text-align: center;
    }
    .mobileIconsDiv{
      display: flex;
      gap: 20px;
      justify-content: center;
      img{
        width: 45px;
      }
    }
  }
}

.about {
  flex-direction: column;
  display: flex;
  justify-content: center;
  margin: 8% 0% !important;
  position: relative;

  .icon {
    width: 150px;
    // margin-bottom: -50px;
    // margin-left: -5px;
    position: absolute;
    top: -55px;
    left: 0px;
  }

  .inner {
    display: grid;
    grid-template-columns: auto auto;
    justify-content: space-around;
    align-items: center;
    margin: 3% 0% !important;
    // gap: 10px;
    // padding: 0px 30px;
    // overflow-x: hidden;
    .textBox {
      padding: 20px 45px;

      .title {
        color: #303030;
        font-family: "Lato", sans-serif;
        font-size: 70px;
        margin-bottom: 20px !important;
        font-weight: 700;
        letter-spacing: 3.2px;
      }
      .para {
        color: #303030;
        text-align: justify;
        font-family: "Lato", sans-serif;
        font-weight: 300;
        font-size: 20px;
        width: 450px;
        margin-bottom: 0px;
      }
    }

    .cardContainer {
      display: flex;
      flex-direction: column;
      gap: 10px;
      padding-top: 20px;
      .cardBox {
        display: flex;
        // justify-content: flex-end;
        gap: 10px;
        // margin-top: 10px;
        .singleCard {
          display: flex;
          flex-direction: column;
          gap: 15px;

          .card {
            transition: 0.3s ease-in-out;
            cursor: pointer;
            // &:hover {
            //   transform: scale(1.05);
            // }
          }
          img {
            width: 230px;
            height: fit-content;
          }
        }
      }
      .shadow {
        display: flex;
        justify-content: flex-end;
        gap: 10px;
        img {
          width: 230px;
          height: fit-content;
        }
      }
    }

    .mobilecardContainer {
      display: none;
    }
  }
}

@media (min-width: 1201px) and (max-width: 1300px) {
  .about {
    .inner {
      .textBox {
        .para {
          width: 380px;
        }
      }
    }
  }
}
@media (min-width: 1301px) {
  .about {
    .icon {
      top: -84px;
      left: 20px;
    }
    .inner {
      .textBox {
        padding: 0px;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .about {
    overflow: auto;
    .icon {
      width: 100px;
      top: -10px;
    }
    .inner {
      display: flex;
      flex-direction: column;
      margin-top: 10% !important;
      .textBox {
        padding: 0px 30px;
        margin-top: 30px;
        // padding: 0px;
        .title {
          font-size: 40px;
          margin-bottom: 15px !important;
        }

        .para {
          font-size: 20px;
          width: fit-content;
        }
      }

      .cardContainer {
        display: none;
      }

      .mobilecardContainer {
        display: block;
        margin-top: 30px;

        .cardBox {
          display: flex;
          flex-direction: column;
          gap: 30px;
          .mobileSingleCover {
            display: flex;
            flex-direction: column;
            gap: 20px;
            align-items: center;
            .singleCard {
              .card {
                transition: 0.3s ease-in-out;
                cursor: pointer;
                width: 320px;
              }

              // img {
              //   width: 200px;
              //   height: fit-content;
              // }
            }
            .mobileShadow {
              width: 320px;
            }
          }
        }
      }
    }
  }
}

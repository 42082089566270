.technology {
  display: grid;
  grid-template-columns: 40% 60%;
  padding: 100px 50px;
  .text {
    position: relative;

    .web {
      width: 110px;
      position: absolute;
      top: -90px;
      left: -20px;
      z-index: -1;
    }

    .firstText {
      color: #303030;
      font-family: "Lato", sans-serif;
      font-size: 70px;
      font-weight: 700;
      margin: 0px;
      letter-spacing: 3px;
    }
    .secondText {
      color: #303030;
      font-family: "Lato", sans-serif;
      font-size: 62px;
      font-weight: 700;
      margin: 0px;
      letter-spacing: 10px;
    }
    .para {
      //   background-color: green;
      width: 80%;
      margin-top: 8%;

      & > :nth-child(1) {
        color: #000;
        font-family: Lato;
        font-size: 25px;
        font-weight: 600;
        line-height: 30px; /* 107.143% */
        letter-spacing: 0.28px;
        margin: 0px;
      }
      & > :nth-child(2) {
        color: #303030;
        font-family: Lato;
        font-size: 17px;
        line-height: 30px;
        letter-spacing: 0.18px;
        margin-bottom: 0px;
        margin-top: 5%;
      }
    }
  }
  .image {
    // background-color: aqua;
    height: 400px;
    // display: flex;
    // align-items: flex-end;
    position: relative;

    .topLine {
      width: 400px;
      position: absolute;
      top: 3px;
      left: 140px;
    }
    .bottomLine {
      width: 386px;
      transform: rotate(14deg);
      position: absolute;
      bottom: 4px;
      left: 140px;
      z-index: -1;
    }
    .mobiletopLine{
      display: none;
    }
    .mobilebottomLine{
      display: none;
    }

    .gifContainer {
      display: flex;
      align-items: flex-end;
      gap: 140px;
      width: 100%;

      .outerouter {
        display: flex;
        justify-content: center;
        position: relative;
        .smallOuter {
          background-image: url("../../assets/technologies/circleBorder.png");
          background-size: contain;
          width: 250px;
          height: 250px;
          animation: spin 10s linear infinite;

          @-webkit-keyframes spin {
            0% {
              -webkit-transform: rotate(0deg);
            }
            100% {
              -webkit-transform: rotate(360deg);
            }
          }

          @keyframes spin {
            0% {
              transform: rotate(0deg);
            }
            100% {
              transform: rotate(360deg);
            }
          }
        }
        .smallCircle {
          width: 247px;
          height: 247px;
          top: 50%;
          position: absolute;
          left: 50%;
          transform: translate(-50%, -50%);
        }
        .bigOuter {
          background-image: url("../../assets/technologies/circleBorder.png");
          background-size: contain;
          width: 350px;
          height: 350px;
          animation: spin 10s linear infinite;

          @-webkit-keyframes spin {
            0% {
              -webkit-transform: rotate(0deg);
            }
            100% {
              -webkit-transform: rotate(360deg);
            }
          }

          @keyframes spin {
            0% {
              transform: rotate(0deg);
            }
            100% {
              transform: rotate(360deg);
            }
          }
        }
        .bigCircle {
          width: 347px;
          height: 347px;
          top: 50%;
          position: absolute;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }

      .bigCircle {
        width: 350px;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .technology {
    display: flex;
    flex-direction: column;
    padding: 130px 30px 50px 30px;
    margin-bottom: 100% !important;
    .text {
      .web {
        top: -100px;
      }
      .firstText {
        font-size: 45px;
        letter-spacing: 1.5px;
      }
      .secondText {
        font-size: 45px;
        letter-spacing: 4.5px;
      }
      .para {
        width: 100%;
        text-align: justify;
      }
    }
    .image {
      .topLine{
        display: none;
      }
      .mobiletopLine {
        display: block;
        position: absolute;
        top: 180px;
        left: 22px;
        height: 350px;
        transform: rotate(2deg);
        z-index: -1;
      }
      .gifContainer {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 100px;
        margin: 50px 0px !important;
        .outerouter {
          .bigOuter {
            width: 330px;
            height: 330px;
          }
          .bigCircle {
            width: 327px;
            height: 327px;
          }
        }
      }
      .bottomLine{
        display: none;
      }
      .mobilebottomLine {
        display: block;
        position: absolute;
        top: 190px;
        right: 20px;
        height: 350px;
        z-index: -1;
        transform: rotate(169deg);
      }
    }
  }
}

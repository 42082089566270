.info {
  padding: 30px 60px;
  display: flex;
  justify-content: space-between;
  position: relative;
  margin-top: 8%;

  .text {
    width: 400px;
    color: #303030;
    font-family: "Lato", sans-serif;

    .settingIcon {
      position: absolute;
      bottom: 7px;
      left: -180px;
      rotate: 270deg;
      z-index: -1;
    }

    .feature {
      font-weight: 700;
      font-size: 80px;
      line-height: 80.2px;
      letter-spacing: 3.2px;
    }
    .service {
      font-weight: 700;
      font-size: 72px;
      line-height: 80.2px;
      letter-spacing: 10.08px;
    }
  }
  .para {
    width: 400px;
    font-family: "Lato", sans-serif;
    display: flex;
    flex-direction: column;
    gap: 15px;
    .title {
      color: #000;
      font-size: 28px;
      font-weight: 600;
      // line-height: 30px;
      letter-spacing: 0.28px;
      margin: 0px;
    }
    .content {
      color: #303030;
      text-align: justify;
      font-size: 18px;
      font-weight: 400;
      // line-height: 27px;
      letter-spacing: 0.18px;
      margin: 0px;
    }
  }
}

.cardsDiv {
  display: grid;
  grid-template-columns: 10% 80% 10%;
  padding: 30px 60px;

  .scrollCards {
    display: flex;
    justify-content: left;
    overflow: hidden;

    .card {
      background: #000;
      border: 1px solid rgba(61, 220, 132, 0.2);
      padding: 20px;
      font-size: 30px;
      text-align: center;
      height: 500px;

      &:hover {
        background: linear-gradient(180deg, #003a2b 0%, #2b847e 100%),
          linear-gradient(0deg, #3ddc84, #3ddc84);

        .detail {
          color: white;
          border: 1px solid white;

          .arrowIconDiv {
            background-image: url("../../assets/F-services/whiteArrow.png");
          }
        }

        .numberDiv {
          border-bottom: 2px solid #3ddc84;
          .first {
            color: #3ddc84;
          }
        }

        .iconDiv1 {
          background-image: url("../../assets/F-services/hoverWebsite.png");
        }
        .iconDiv2 {
          background-image: url("../../assets/F-services/hoverIphone.png");
        }
        .iconDiv3 {
          background-image: url("../../assets/F-services/hoverHorn.png");
        }
        .iconDiv4 {
          background-image: url("../../assets/F-services/hoverHandWithPen.png");
        }
        .iconDiv5 {
          background-image: url("../../assets/F-services/hoverMangement.png");
        }
        .iconDiv6 {
          background-image: url("../../assets/F-services/hoverEmail.png");
        }
      }
    }
  }

  .arrowDiv {
    cursor: pointer;
    background: #000;
    border-right: 1px solid rgb(187 242 213 / 74%);
    border-left: 1px solid rgb(187 242 213 / 74%);
    display: flex;
    align-items: center;
    justify-content: center;

    .arrowIcon {
      background-size: contain;
      background-repeat: no-repeat;
      width: 68px;
      height: 68px;

      &:hover {
        background-image: url("../../assets/F-services/servicehoverArrow.png");
      }
    }

    .leftArrow {
      background-image: url("../../assets/F-services/serviceArrow.png");
    }
    .rightArrow {
      background-image: url("../../assets/F-services/serviceArrow.png");
      transform: rotate(180deg);
    }
  }
}

.mobilecardsDiv {
  display: none;
}

.mobileArrowDiv{
  display: none;
}

.singleCard {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 265px;

  .logo {
    display: flex;
    justify-content: space-between;
    align-items: center;

    // .iconDiv {
    //   // background-color: rgba(0, 102, 46, 0.26);
    //   // border-radius: 50%;
    //   // height: 60px;
    //   // width: 60px;
    //   // display: flex;
    //   // justify-content: center;
    //   // align-items: center;
    //   // border: 2px solid rgba(255, 255, 255, 0.3);

    //   .icon {
    //     height: 60px;
    //     width: 60px;
    //   }
    // }

    .iconDiv1 {
      background-image: url("../../assets/F-services/Website.png");
      background-size: contain;
      height: 60px;
      width: 60px;
    }
    .iconDiv2 {
      background-image: url("../../assets/F-services/iPhone.png");
      background-size: contain;
      height: 60px;
      width: 60px;
    }
    .iconDiv3 {
      background-image: url("../../assets/F-services/horn.png");
      background-size: contain;
      height: 60px;
      width: 60px;
    }
    .iconDiv4 {
      background-image: url("../../assets/F-services/HandWithPen.png");
      background-size: contain;
      height: 60px;
      width: 60px;
    }
    .iconDiv5 {
      background-image: url("../../assets/F-services/Management.png");
      background-size: contain;
      height: 60px;
      width: 60px;
    }
    .iconDiv6 {
      background-image: url("../../assets/F-services/Email.png");
      background-size: contain;
      height: 60px;
      width: 60px;
    }

    .numberDiv {
      height: 65px;
      border-bottom: 2px solid rgb(43 181 104 / 32%);

      .first {
        margin: 0px;
        color: rgba(61, 220, 132, 0.2);
        -webkit-text-stroke: 1px rgba(61, 220, 132, 0.2);
        font-family: "Nunito", sans-serif;
        font-size: 17px;
        .second {
          font-size: 27px;
        }
      }
    }
  }
  .bodyContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 80%;

    .body {
      text-align: left;
      display: flex;
      flex-direction: column;
      gap: 20px;

      .title {
        color: #fff;
        font-family: "Urbanist", sans-serif;
        font-size: 22px;
        font-weight: 700;
        letter-spacing: 0.88px;
        white-space: nowrap;
        margin: 0px;
      }

      .about {
        color: rgba(255, 255, 255, 0.7);
        font-family: "Urbanist", sans-serif;
        font-size: 16px;
        font-weight: 400;
        margin: 0px;
        letter-spacing: 0.16px;
      }
    }

    .detail {
      border-radius: 65px;
      border: 1px solid rgba(61, 220, 132, 0.2);
      background: rgba(23, 23, 23, 0.3);
      display: flex;
      align-items: center;
      padding: 12px 0px;
      justify-content: center;
      gap: 10px;
      font-family: "Lato", sans-serif;
      cursor: pointer;

      &:hover {
        background-color: #000;
        border: none !important;
        color: white;

        // .arrowIconDiv {
        //   background-image: url("../../assets/F-services/whiteArrow.png");
        // }
      }

      color: rgba(255, 255, 255, 0.5);
      span {
        font-family: "Lato", sans-serif;
        font-size: 18px;
        font-weight: 600;
      }
      .arrowIconDiv {
        background-image: url("../../assets/F-services/arrowIcon.png");
        height: 18px;
        width: 18px;
        background-size: contain;

        // &:hover{
        //   background-image: url('../../assets/F-services/whiteArrow.png');
        // }
      }
    }
  }
}

@media (min-width: 1301px) {
  .singleCard {
    width: 331px;
  }
}

@media only screen and (max-width: 768px) {
  .info {
    flex-direction: column;
    padding: 100px 30px 60px 30px;
    .text {
      width: fit-content;
      margin-bottom: 20px !important;
      .settingIcon {
        bottom: 210px;
        left: -120px;
        height: 380px;
      }
      .feature {
        font-size: 40px;
        line-height: normal;
      }
      .service {
        font-size: 40px;
        line-height: normal;
        letter-spacing: 5.5px;
      }
    }
    .para {
      width: fit-content;
      .title {
        font-size: 25px;
        letter-spacing: 0.5px;
      }
      .content {
        line-height: 30px;
        letter-spacing: 0.3px;
      }
    }
  }

  .cardsDiv {
    display: none;
  }
  .mobilecardsDiv {
    display: flex;
    justify-content: center;
    background: black;
    margin-bottom: 5px !important;

    .scrollCards {
      display: flex;
      background: linear-gradient(180deg, #003a2b, #2b847e),
        linear-gradient(0deg, #3ddc84, #3ddc84);
      width: 300px;
      overflow: hidden;

      .card {
        border: 1px solid rgba(61, 220, 132, 0.2);
        padding: 20px;
        font-size: 30px;
        text-align: center;
        height: 500px;

        .singleCard {
          .logo {
            .numberDiv {
              border-bottom: 2px solid #3ddc84;
              .first {
                color: #3ddc84;
              }
            }
            .iconDiv1 {
              background-image: url("../../assets/F-services/hoverWebsite.png");
            }
            .iconDiv2 {
              background-image: url("../../assets/F-services/hoverIphone.png");
            }
            .iconDiv3 {
              background-image: url("../../assets/F-services/hoverHorn.png");
            }
            .iconDiv4 {
              background-image: url("../../assets/F-services/hoverHandWithPen.png");
            }
            .iconDiv5 {
              background-image: url("../../assets/F-services/hoverMangement.png");
            }
            .iconDiv6 {
              background-image: url("../../assets/F-services/hoverEmail.png");
            }
          }
          .bodyContainer {
            .body {
              .about {
                color: white;
              }
            }
            .detail {
              color: white;
              border: 1px solid white;

              .arrowIconDiv {
                background-image: url("../../assets/F-services/whiteArrow.png");
              }
            }
          }
        }
      }
    }
  }
  .mobileArrowDiv {
    background-color: black;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 25px;
    .arrowIcon {
      background-size: contain;
      background-repeat: no-repeat;
      width: 68px;
      height: 68px;
      background-image: url("../../assets/F-services/servicehoverArrow.png");
    }

    .rightArrow {
      transform: rotate(180deg);
    }
  }
}

.main {
  padding: 10px 30px;

  .whyCloud {
    display: flex;
    justify-content: space-between;
    font-family: Lato;
    .text {
      color: #303030;
      position: relative;
      padding-left: 20px;

      .questionIcon {
        width: 130px;
        position: absolute;
        top: -100px;
        left: -40px;
      }
      & > :nth-child(2) {
        font-size: 70px;
        font-weight: 700;
        letter-spacing: 3px;
      }
      & > :nth-child(3) {
        font-size: 62px;
        font-weight: 700;
        letter-spacing: 8.5px;
      }
    }
    .para {
      width: 330px;
      color: #000;
      & > :nth-child(1) {
        font-size: 28px;
        font-weight: 600;
        margin-bottom: 10px !important;
      }
      & > :nth-child(2) {
        font-size: 20px;
        font-weight: 400;
      }
    }
  }

  .sliderDiv {
    padding: 50px 0px;
    overflow: visible !important;

    .marque {
      overflow: visible !important;

      .singleCard {
        // background: linear-gradient(
        //   to bottom,
        //   #ffffff 0%,
        //   #ffffff 50%,
        //   #034031 50%,
        //   #034031 100%
        // );

        background: linear-gradient(
          to bottom,
          #ffffff 0%,
          #ffffff 35%,
          #034031 35%,
          #034031 100%
        );

        padding: 20px;
        border-top: 4px solid #727272;
        position: relative;
        overflow: visible !important;

        .leftOuter {
          position: absolute;
          overflow: visible !important;
          z-index: 4;
          top: -34px;
          left: 40px;
          .leftSlider {
            width: 30px;
          }
        }

        .rightOuter {
          position: absolute;
          overflow: visible !important;
          z-index: 4;
          top: -34px;
          right: 40px;
          .rightSlider {
            width: 30px;
          }
        }

        .cardContent {
          width: 300px;
          height: 400px;
          background: #f1f6f3;
          padding: 30px;

          .innerCard {
            display: flex;
            flex-direction: column;
            gap: 12px;
            .cardIcon {
              width: 40px;
            }
            .cardTitle {
              color: #000;
              font-family: Lato;
              font-size: 16px;
              font-weight: 600;
              letter-spacing: 0.2px;
            }
            .cardPara {
              color: #303030;
              font-family: Lato;
              font-size: 15px;
            }
          }
        }
      }
    }
  }

  .mobileCardsContainer{
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  .main {
    padding: 0px 30px;
    margin-bottom: 20% !important;
    .whyCloud {
      flex-direction: column;
      margin-bottom: 10% !important;
      .text {
        padding: 0px;
        margin-bottom: 15px !important;

        .questionIcon {
          width: 100px;
          top: -80px;
        }
        & > :nth-child(2) {
          font-size: 46px;
          font-weight: 700;
          letter-spacing: 1.84px;
        }
        & > :nth-child(3) {
          font-size: 46px;
          font-weight: 700;
          letter-spacing: 5.52px;
        }
      }
    }
    .sliderDiv {
      display: none;
    }
    .mobileCardsContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 25px;

      .mobileCard {
        background-color: #f0f6f2;
        width: 330px;
        height: 450px;
        border-radius: 5px;
        padding: 30px;
        display: flex;
        flex-direction: column;
        gap: 13px;
        .cardIcon {
          width: 50px;
        }
        .cardTitle {
          font-size: 20px;
          color: #000;
          font-weight: 600;
        }
        .cardPara {
          font-size: 18px;
          color: #303030;
        }
      }
    }
  }
}

.contactForm {
  padding: 50px 30px;
  display: flex;
  justify-content: space-between;
  .text {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
    padding-left: 10px;
    .contactGif {
      width: 160px;
      position: absolute;
      top: -70px;
      left: -60px;
    }
    .heading {
      margin-bottom: 5% !important;
      & > :nth-child(1) {
        color: #303030;
        font-family: Lato;
        font-size: 60px;
        font-weight: 700;
        letter-spacing: 10px;
        margin: 0px;
      }
      & > :nth-child(2) {
        color: #303030;
        font-family: Lato;
        font-size: 55px;
        font-weight: 700;
        letter-spacing: 10px;
        margin: 0px;
      }
    }
    .para {
      width: 350px;
      & > :nth-child(1) {
        color: #000;
        font-family: Lato;
        font-size: 25px;
        font-weight: 600;
        line-height: 30px;
        letter-spacing: 0.3px;
        margin-bottom: 5% !important;
      }
      & > :nth-child(2) {
        color: #303030;
        font-family: Lato;
        font-size: 18px;
        font-weight: 400;
      }
    }
  }

  .formDiv {
    .form {
      width: 700px;
      border-radius: 4px;
      background: #034031;
      padding: 50px;

      display: grid;
      grid-template-columns: auto auto;
      gap: 15px;

      .inputDiv {
        border-radius: 4px;
        border: 0.5px solid rgba(48, 48, 48, 0.4);
        padding: 10px;
        background: #fff;
        display: flex;
        align-items: center;
        gap: 10px;

        .contactIcon {
          width: 15px;
        }
        .w_20 {
          width: 20px;
        }
        .input {
          border: none;
          font-family: Lato;
          outline: none;
          font-size: 18px;
          resize: none;
          padding: 0px;
        }

        .countryDiv {
          display: flex;
          align-items: center;
          gap: 10px;
          width: 90%;
        }
      }
      .messageDiv {
        grid-column-start: 1;
        grid-column-end: 3;
        display: flex;
        align-items: flex-start;
      }
      .submitBtn {
        grid-column-start: 1;
        grid-column-end: 3;
        border-radius: 4px;
        background: #171717;
        padding: 10px;
        border: none;
        color: #fff;
        font-family: Lato;
        font-size: 18px;
        outline: none;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .contactForm {
    flex-direction: column;
    padding: 30px ;
    gap: 30px;
    .text {
      padding: 0px;
      .contactGif {
        width: 120px;
        top: -90px;
        left: -35px;
      }
      .heading {
        & > :nth-child(1) {
          font-size: 46px;
          letter-spacing: 1.8px;
        }
        & > :nth-child(2) {
          font-size: 46px;
          letter-spacing: 6.4px;
        }
      }
      .para {
        width: fit-content;
      }
    }
    .formDiv {
     
      .form{
        padding: 20px;
        display: flex;
        flex-direction: column;
        max-width: -webkit-fill-available;

      }
    }
  }
}

.carouselDiv {
  .image {
    width: 100%;
    // height: 450px;
    // height: 250px;  //for mobile
  }

  .carousel {
    background-color: #cccccc;
    // height: 450px;
    height: 80vh;
    width: 100%;
    background-size: cover;
    display: grid;
    grid-template-columns: repeat(15, auto);


    .btn {
      display: flex;
      align-items: center;
      gap: 10px;
      border: 1px solid white;
      height: fit-content;
      width: fit-content;
      padding: 15px 90px;
      border-radius: 50px;
      grid-row-start: 5;
      grid-column-start: 2;
      white-space: nowrap;
      background: rgba(23, 23, 23, 0.3);
      cursor: pointer;
      p {
        color: #fff;
        font-family: Lato;
        font-size: 22px;
        font-weight: 600;
        margin-bottom: 0px;
      }
      img {
        width: 20px;
      }

      &:hover {
        background-color: black;
      }
    }
  }

  .carousel1 {
    background-image: url("../../assets/carousel/11.png");
  }
  .carousel2 {
    background-image: url("../../assets/carousel/22.png");
  }
  .carousel3 {
    background-image: url("../../assets/carousel/33.png");
  }

  
}
.MobileCarouselDiv {
  display: none;
}

@media only screen and (max-width: 768px) {
  .carouselDiv {
    display: none;
  }
  .MobileCarouselDiv {
    display: block;
    .carousel {
      height: 60vh;
      width: 100%;
      background-position: centre;
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }

    .mobileCarousel1 {
      background-image: url("../../assets/carousel/mobile11.png");
    }
    .mobileCarousel2 {
      background-image: url("../../assets/carousel/mobile11.png");
    }
    .mobileCarousel3 {
      background-image: url("../../assets/carousel/mobile11.png");
    }
  }
}


.navbar {
  height: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 40px;

  background: #fff;
  box-shadow: 0px 4px 200px 0px rgba(0, 0, 0, 0.2);
  .hamburger {
    display: none;
    width: 45px;
    height: 45px;
  }

  .logo {
    width: 80px;
    height: 80px;
  }
  .links {
    display: flex;
    gap: 20px;
    .link {
      color: #303030;
      font-family: "Urbanist", sans-serif;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.16px;

      list-style: none;
      cursor: pointer;

      &:hover {
        color: black;
      }
    }
  }
  .contact {
    display: flex;
    align-items: center;
    gap: 10px;

    .phonelogo {
      width: 15px;
      height: 15px;
    }
    .phoneText {
      color: #303030;
      font-family: "Urbanist", sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.16px;
    }
  }
}

@media only screen and (max-width: 768px) {
  .navbar {
    .hamburger {
      display: block;
    }
    .logo {
      width: 60px;
      height: 60px;
    }
    .links {
      display: none;
    }
    .contact {
      display: none;
    }
  }
}
